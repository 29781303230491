.app-side-top-controller {
    position: fixed;
    z-index: 99999;
    right: 1em;
    top:6em;
    width: 25em;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: stretch;
}
.app-side-top-controller .message-tip{
    margin: 0.3em 0;
}
