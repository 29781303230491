.com-header-school-selector {
    display: block;
    position: relative;
    margin: 0 0.5em;
}
.com-header-school-selector .school-label {
    display: inline-block;
    padding: 0 1em;
    min-width: 12em;
    text-align: center;
    background-color: #ffc800;
    line-height: 2em;
    color: white;
    border-radius: 1em;
}
.com-header-school-selector .school-label .text-label{}
.com-header-school-selector .school-item{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 2em;
    padding: 0 1em;
    text-align: center;
}
.com-header-school-selector .school-item:hover{
    background-color: #ffc800;color: white;
}
.com-header-school-selector .list-wrap.opend{display: block;}
.com-header-school-selector .list-wrap{
    display: none;
    width: 100%;
    background-color: white;
    box-shadow: 1px 1px 4px #ccc;
    position: absolute;
    left: 0;
    top: 4em;
    border-radius: 3px;
}
