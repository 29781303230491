* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

p {
    font-size: 0.16rem;
    margin: 0;
}

ul, ol, li {
    display: block;
    list-style: none;
}

html {
    font-size: 100px;
    width: 100%;
    height: 100%;
    transition: font-size .2s ease-out;
    overflow: hidden;
}

body {
    width: 100%;
    height: 100%;
    font-size: 16px;
    -webkit-font-smoothing:antialiased ;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Microsoft YaHei', sans-serif;*/
    color: #333;
    line-height: 1.75;
    overflow: hidden;
}
@media
(-webkit-min-device-pixel-ratio: 1.5),
(-webkit-min-device-pixel-ratio: 2),
(min-resolution: 2dppx){
    /* Retina下仍使用默认字体渲染 */
    body {
        -webkit-font-smoothing: subpixel-antialiased;
    }
}
/*@media screen and (max-device-width: 960px) {*/
    /*html {*/
        /*font-size: 50.000px;*/
    /*}*/
/*}*/
/*@media screen and (min-device-width: 980px) {*/
    /*html {*/
        /*font-size: 50.000px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1024px) {*/
    /*html {*/
        /*font-size: 53.333px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1080px) {*/
    /*html {*/
        /*font-size: 56.250px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1120px) {*/
    /*html {*/
        /*font-size: 58.333px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1200px) {*/
    /*html {*/
        /*font-size: 62.500px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1280px) {*/
    /*html {*/
        /*font-size: 66.667px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1360px) {*/
    /*html {*/
        /*font-size: 70.833px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1366px) {*/
    /*html {*/
        /*font-size: 71.146px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1400px) {*/
    /*html {*/
        /*font-size: 72.917px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1440px) {*/
    /*html {*/
        /*font-size: 75.000px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1600px) {*/
    /*html {*/
        /*font-size: 83.333px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1680px) {*/
    /*html {*/
        /*font-size: 87.500px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1900px) {*/
    /*html {*/
        /*font-size: 98.958px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 1920px) {*/
    /*html {*/
        /*font-size: 100.000px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 2048px) {*/
    /*html {*/
        /*font-size: 106.667px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 2560px) {*/
    /*html {*/
        /*font-size: 133.333px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 3200px) {*/
    /*html {*/
        /*font-size: 166.667px;*/
    /*}*/
/*}*/

/*@media screen and (min-device-width: 3840px) {*/
    /*html {*/
        /*font-size: 200.000px;*/
    /*}*/
/*}*/

.root {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}