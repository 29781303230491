.def-tree.ant-tree {
    margin-bottom: 3em;
}
.ant-tree li .ant-tree-node-content-wrapper {position: relative;line-height: 2.4em;}
.def-tree .anticon{vertical-align: middle;}
.def-tree .def-tree-node{position: relative;width: auto;}
.def-tree .def-tree-node >.ant-tree-node-content-wrapper:hover{margin-bottom: 2em;}
.def-tree .def-tree-node .def-node-menu-wrap{
    position: absolute;
    z-index: 2;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 3px #aaa;
    top: 90%;
    left: 0%;
    visibility: hidden;
    padding: .2em 1em;
    transition: all .2s;
    min-width: 100%;
    text-align: left;
}
.def-tree .def-tree-node .def-node-menu-wrap .menu-item{margin: 0 .5em;}
/*.def-tree .def-tree-node .ant-tree-node-selected .def-node-menu-wrap,*/
.def-tree .ant-tree-node-content-wrapper:hover .def-node-menu-wrap{
    top: 100%;
    visibility:visible;
}
.def-tree .ant-tree li span.ant-tree-switcher, .ant-tree li span.ant-tree-iconEle{line-height: 2em;}
.def-tree .ant-tree-title {display: inline-block;line-height: 2.4em;vertical-align: top;}
.def-tree .ant-tree-title:after {content: '';clear: both;}
.def-tree .tree-node-title {display: inline-block;line-height: inherit;vertical-align: top;}
.def-tree .tree-node-title-inner {display: inline-block;line-height: inherit;vertical-align: top;}
.def-tree .tree-node-title-name {
    display: inline-block;line-height: inherit;vertical-align: top;
    max-width: 25em;text-overflow: ellipsis;overflow: hidden;
}
.def-tree .tree-node-title-des {
    display: inline-block;font-size:.9em;color:#bbb;margin: 0 .5em;max-width: 15em;
    text-overflow: ellipsis;overflow: hidden;line-height: inherit;vertical-align: top;
}

