.app-module-link {display: inline-block;cursor: pointer;}
.btn {
    display: inline-block;
    font-size: 1em;
    background-color: #eee;
    border: 1px solid #ccc;
    color: #ccc;
    text-align: center;
    line-height: 1.5em;
    vertical-align: baseline;
    border-radius: 3px;
    cursor: pointer;
    padding: 0 1em;
}
.btn.loginSubmit{
    font-size: .18rem;
    line-height: 3em;
    border-radius: 1.5em;
}
.btn:hover {}
.btn.inline {
    width: 100%;
}
.btn.red {
    background-color: #FF7D79;color: white;border-color: #FF7D79;
}
.btn.red:hover {
    background-color: #ff988e;
}
.btn.blue {
    background-color: #3ca9ff;color: white;border-color: #3ca9ff;
}
.btn.blue:hover {
    background-color: #76daff;
}
.btn.orange {
    background-color: #ffc800;color: white;border-color: #ffc800;
}
.btn.orange:hover {
    background-color: #ffdb4a;
}
.btn.green {
    background-color: #35c83d;color: white;border-color: #35c83d;
}
.btn.green:hover {
    background-color: #7bc872;
}