@import "~antd/dist/antd.css";
.App {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.App .app-row-ctrl-btn {
    margin: 0.5em 0;
}
.App .app-tb-ctrl-btn {
    margin:3px;
}
.App .app-img-wrap {
    position: relative;
    width: auto;
    display: inline-block;
}
.App .app-img-wrap .btn-del{
    position: absolute;
    left: 100%;
    top: 0;
}
.q-rich-html img {max-width: 100%;}
.q-rich-html span {margin: 0 !important;}
.q-rich-html.inline {display: inline;}
.q-rich-html.inline *{display: inline;}
.App a {color: #333;text-decoration: none;}
.module-paranel {
    width: 100%;
    height: 100%;
}


/* transition*/
.trans{
	transition: all .2s;
}
/*layout*/
.layout-min-center {margin: 0 auto;}
.flex-box {width: 100%;height: 100%;display: flex;flex-direction: column;}
.flex-box .cc {justify-content: center;align-items: center;}
.flex-box .flex-fill {flex:1 1;height: 100%;}

/*pading*/
.pd20 {
    padding: .2rem;
}
.pd15 {
    padding: .15rem;
}
.pd10 {
    padding: .1rem;
}
/*white-box*/
.white-box {
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 0 3px;
}
/*flex-line*/
.flex-line {display: flex;flex-flow: row;line-height: 2.5em;}
/*app main layout*/
.app-layout-wrap {display: flex;flex-flow: column;}
.app-layout-header {background-color: white;box-shadow: 0 0 .1rem;z-index: 1;}
.app-layout-module-content {flex: 1 1;overflow: auto;background-color: #eee;z-index: auto;}


/*module-app-nav-header*/
/*iconfont*/
.App .iconfont {
    font-size:.18rem;
    color: #666;
}
.App .iconfont:hover {
    color: #333;
}
.App .module-view {
    height: 100%;
    min-width: 820px;
    position: relative;
}
.App .module-flex-view {
    height: 100%;
    min-width: 820px;
    position: relative;
    display: flex;
    flex-flow: row;
}
.App .module-flex-view .flex-content{
    flex: 1 1;background-color: white;
}
/*app-scroll-container*/
.app-tool-tip-wrap {
    display: block;
}
.app-tool-tip-wrap .row-wrap{white-space: nowrap;}
.ant-tooltip-content {width: fit-content;}
.app-scroll-container {
    position: relative;display: block;width: 100%;height: 100%;overflow: hidden;
}

.app-scroll-container .app-scrolller{
    position: absolute;display: block;z-index: 0;width:auto;height: auto;left: 0;top:0;
}
.app-scroll-container.v .app-scrolller{
    height: auto;width: 100%;padding-right: 1em;
}
.app-scroll-container.h .app-scrolller{
    height: 100%;width: auto;
}
.app-scroll-container.f .app-scrolller{
    height: auto;width: auto;
}



/*app-side-bar*/
.app-side-bar {
    height: 100%;width: 10em;position: relative;
}
.tutor-layout .app-side-bar {
    height: 100%;width: 10em;position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: stretch;
}
.app-side-bar .side-bar-content{
    height: auto;width:100%;overflow-x:hidden;
}
.app-side-bar .side-bar-controller {
    position: absolute;z-index: 1;cursor: pointer;
    left: 100%;background-color: rgba(0,0,0,0.1);
    width: 15px;height: 50px;top:calc(50% - 25px);
    border-top-right-radius: 5px;line-height: 50px;
    border-bottom-right-radius: 5px;text-align: center;
}
.app-side-bar .side-bar-controller:after{content: '\e61b';color:white;font-size: 14px;}
.app-side-bar .side-bar-controller:hover {background-color: rgba(0,0,0,0.5);}
.app-side-bar.hide {width: 0;}
.app-side-bar.hide .side-bar-controller {width: 25px;}
.app-side-bar.hide .side-bar-controller:after {content: '\e61c'}

/*app-second-module-menu*/
.app-second-module-menu {padding-top: .5em;}
.app-second-module-menu .menu-list{display: inline-block;text-align: right;width: 100%;padding:5px 0;}
.app-second-module-menu .app-module-link{display: inline-block;width: 100%;color: #fff;}
.app-second-module-menu .menu-item{
    display: inline-block;text-align: center;padding:0 .5em;line-height: 2.5em; background-color: #ffc800;box-shadow: -1px 1px 3px #999;
    white-space: nowrap;width: 80%;border-top-left-radius: 3px;border-bottom-left-radius: 1em;margin: -1px 0;z-index: 0;position: relative;
    font-size: 1.2em;
}
.app-second-module-menu .menu-item.selected {
    width: 98%;box-shadow: -1px 1px 3px #999;z-index: 1;
    background-color: #fff;border-top-left-radius: .5em;
    background-color: #fff;border-bottom-left-radius: .5em;
}
.app-second-module-menu .menu-item.selected .app-module-link{
    color: #333;font-size: 1.3em;
}
/*.app-second-module-menu .menu-item:hover{background-color: #ffc800;}*/
/*.app-second-module-menu .menu-item:hover .app-module-link{color: white;}*/
/*app-query-wrap*/
.app-query-wrap {padding: .3em .5em;border-bottom: 1px solid #eee;display: block;    margin-bottom: 12px;}
.app-query-wrap:after {content: '';display: block;line-height: 0;height: 0;clear: both;}
.app-query-wrap .app-query-group{display: inline-block;}
.app-query-wrap .app-query-item.right{float: right;}
.app-query-wrap .app-query-item{margin-right: 1em;white-space: nowrap;display: inline-block;vertical-align: baseline;}
.app-query-wrap .app-query-line-wrap{display: flex;flex-flow: row;justify-content: flex-start;align-items: center;}


.app-list-wrap {}
.app-list-wrap .ctrl-btn{margin: 0 .5em;}
/*app-pagination-wrap*/
.app-pagination-wrap {padding: .2em;border-top: 1px solid #eee;text-align: center;}

.app-list-tip-wrap {
    text-align: center;padding: 1em;
    color: #ccc;
}
.app-data-empty {
    color: #9C9C9C;
    text-align: center;
    line-height: 4em;
}
.required {position: relative;display: inline-block;}
.required:before{
    content: '*';
    color: #ff0000;
    position: absolute;
    display: inline-block;
    line-height: normal;
    z-index: 0;
    left: -.7em;
    top:32%;
}
/*def-form-table*/
.def-form-table {width: 100%;}
.def-form-table td {padding: .5em;line-height: 32px;}
.def-form-table tr {}
.def-form-table td.h{text-align: right;width: 6em;white-space: nowrap;}
/*app-no-data*/
.app-no-data {
    font-size: 1em;
    color: #bfbfbf;
    padding: 0 1em;
}


.condition-list-wrap{
    padding: 0 1em 0 .5em;
}
.condition-box{
    box-shadow: 0 0 4px #ccc;margin: .2em auto  0.5em auto;
    border-radius: 3px;background-color: white;border: 1px solid #ccc;
    position: relative;
}
.condition-box .btn-delete{
    position: absolute;
    z-index: 2;
    right: 5px;top: 5px;
    cursor: pointer;
}
.condition-box>.title{
    color: #999;font-size: 1em;line-height: 2em;
    border-bottom: 1px solid #eee;padding: 0 .2em 0 .5em;
    border-left: 3px solid #ffc800;
    display: flex;flex-flow: row;
    border-top-left-radius:3px;
    border-bottom-left-radius:3px;
    justify-content: space-between;
    align-items: center;
}
.condition-box>.title>.start{
    text-align: left;width: auto;
}
.condition-box>.title>.end{
    text-align: right;width: auto;
}
.condition-box>.title>.fill{
    text-align: right;flex: 1 1;padding: 0 .5em 0 .5em;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}
.condition-box>.content{
    padding: .5em;
}
.condition-box .noborder{border:0;}
.condition-box .custom-drop-wrap{width: auto;}
.condition-box .noborder .ant-select-selection{border: 0;}
.content-value-wrap {display: flex;flex-flow: row;}
.content-value-wrap .audio-btn-close {
    display: inline-block;
    line-height: 2.8em;
    margin-left: 1em;
    cursor: pointer;
}
.content-value-wrap .audio-btn-close:hover {color: #00ACFF;}
.condition-group {
    padding: 1.8em 0.5em;
    border: 1px dashed #ccc;
    background-color: #eee;
    margin-bottom: 0.5em;
    box-shadow: 0 0 3px #ccc;
    position: relative;
}
.condition-box .btn-add {
    display: block;
    border: 1px dashed #d9d9d9;
    padding: .5em;
    cursor: pointer;
    font-size: .8em;
    line-height: 2em;
    border-radius: 3px;
    text-align: center;
}
.app-icon-view {
    display: inline-block;
    box-shadow: 0 0 3px #ccc;
}


.app-index-setter-ctrl-btn {
    
}

/*question list*/
.q-list {display: block;}
.q-list .ref-id-label{
    display: inline-block;margin: .5em 1em;
    color: #00ACFF;
}
.q-list-item {
    display: block;position: relative;
    transform: translate(0,0) translateZ(0);
    animation-duration: .5s;
}
.q-list-item.show-down:after {
    content: '↓';
    position: absolute;
    width: 100%;
    height: 1em;
    left: 0;
    bottom: -.7em;
    z-index: 0;
    text-align: center;
    font-size: 2em;
    color: #fff;
    text-shadow: 0 0 3px #333;
}
.q-list-item>.ctrl-wrap{
    display: block;position: absolute;z-index: 2;
    background-color: white;
    right: 1em;
    top: 100%;box-shadow: -1px 1px 2px #ccc;
    padding:0 1em;
    border-right: 1px solid #eee;
    border-top: 1px solid #fff;

}
.q-list-item:hover>.ctrl-wrap{background-color: #eee;border-top: 1px solid #eee;}
.q-list-item>.ctrl-wrap>.ctrl-item {
    padding: .5em;display: inline-block;
}
.q-list-item-view {
    padding: .5em;
    border-bottom: 1px solid #eee;
    box-shadow: 0 0 3px #ccc;
    margin: 1em 1em 4em 1em;
}
.q-list-item-view:hover {
    box-shadow: 0 0 3px #aaa;
    background-color: #f5f5f5;
}
.q-list-item-view .row {padding: .5em 0}
.q-list-item-view .sub-option-wrap {font-size: .9em;border: 1px dashed #ddd;padding: .5em;margin: 1em 0;}
.q-list-item-view .q-rich-html img {width: 100px;}
.q-list-item-view .row span{margin: 0 .3em;}
.q-list-item-view .row span i{margin: 0 .5em;color: #00ACFF;white-space: normal;word-break: break-word;}
.q-list-item-view .row span label{margin: 0 .5em;color: #ff690d;}
.q-list-item-view .row1 {border-bottom: 1px solid #eee;}
.q-list-item-view .row2 {padding: .5em 0}
.q-list-item-view .row3 {padding: .5em 0}
.q-list-item-view .row4 {border-top: 1px solid #eee;}
.q-list-item-view .row4>span {display: inline-block;white-space: nowrap;}

.q-list-item-view.group-view {}
.q-list-item-view.group-view .q-list-item>.ctrl-wrap{
    display: block;position: absolute;z-index: 2;
    background-color: white;
    left: 1em;
    right: auto;
    top: 100%;box-shadow: -1px 1px 2px #ccc;
    padding:0 1em;
    border-left: 1px solid #eee;
    border-top: 1px solid #fff;
}
.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover {
    background-color: #eee;
}

.app-test-score-setter {}

.app-flex-tabs {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: stretch;
}
.app-flex-tabs > .ant-tabs-bar {display: block;height: auto;}
.app-flex-tabs > .ant-tabs-content {flex: 1 1;}
.app-flex-tabs-pane {
    height: 100%;
}
.app-flex-tabs .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.app-flex-tabs .ant-tabs-tab-next.ant-tabs-tab-arrow-show{
    color: white;background-color: #3ca9ff;
}
.app-flex-tabs .ant-tabs-tab-btn-disabled,
.app-flex-tabs .ant-tabs-tab-btn-disabled:hover{
    color: #999999 !important;
    background-color: white !important;
}
.app-font-main {color: #333;}
.app-font-note {color: #999;}
.app-divider-small.ant-divider-horizontal{margin: .5em 0;}

.app-upload-view-img {max-width: 300px;max-height: 300px;}

.App a.app-text-link {
    color: #00a0e9;
    display: inline-block;vertical-align: middle;margin:0 0.3em;
    cursor: pointer;
}
