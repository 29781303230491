/*!
 * ui-dialog.css
 * Date: 2014-07-03
 * https://github.com/aui/artDialog
 * (c) 2009-2014 TangBin, http://www.planeArt.cn
 *
 * This is licensed under the GNU LGPL, version 2.1 or later.
 * For details, see: http://www.gnu.org/licenses/lgpl-2.1.html
 */
@charset "UTF-8";
.ui-dialog {
    *zoom:1;
    _float: left;
    position: relative;
    border-radius: 3px;
    background-color: #FFF;
    overflow: hidden;
    outline: 0;
    background-clip: padding-box;
    font-family: Microsoft YaHei,Helvetica, arial, sans-serif;
    font-size: 14px;
    line-height: 1.428571429;
    color: #666;
    opacity: 0;
    transform: scale(0);
    transition: transform .15s ease-in-out, opacity .15s ease-in-out;
}
.ui-popup-show .ui-dialog {
    opacity: 1;
    transform: scale(1);
}
.ui-popup-focus .ui-dialog {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
.ui-popup-modal .ui-dialog {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), 0 0 256px rgba(255, 255, 255, .3);
    border: 1px solid #e5e5e5;overflow: visible;
}
.ui-dialog-grid {
    width: auto;
    margin: 0;
    border: 0 none;
    border-collapse:collapse;
    border-spacing: 0;
    background: transparent;
}
.ui-dialog-header,
.ui-dialog-body,
.ui-dialog-footer {
    padding: 0;
    border: 0 none;
    text-align: left;
    background: transparent;
}
.ui-dialog-footer {
    border-top: 1px solid #eee;
}
.ui-dialog-header {
    white-space: nowrap;
    background-color: #f3f3f3;
    border-bottom: 1px solid #ededed;
}
.ui-dialog-close {
    position: relative;
    float: right;
    top: 0px;
    right: 7px;
    height: 32px;
    line-height: 32px;
    padding: 0 4px;
    font-size: 26px;
    color: #fe860c;
    cursor: pointer;
    background: transparent;
    _background: #FFF;
    border: 0;
    opacity: .6;
    -webkit-appearance: none;
}
.ui-dialog-close:hover,
.ui-dialog-close:focus {
    opacity: 1;
}

.ui-dialog-title {
    padding: 0 15px;
    height: 32px;
    box-sizing: border-box;
    background-color: #fff;
    line-height: 32px;
    font-size: 16px;
    color: #333;
}

.ui-dialog-title:before {
    content: '';
    display: inline-block;
    height: 22px;
    margin-right: 10px;
    border-left: solid 3px #ffc800;
    vertical-align: middle;
}

.ui-dialog-body {
    padding: 5px 15px 0 15px;
    text-align: center;
}
.ui-dialog-content {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    *zoom: 1;
    *display: inline;
    text-align: left;
    color:#666;
}
.ui-dialog-footer {
    padding:5px 15px
}
.ui-dialog-statusbar {
    float: left;
    margin-right: 20px;
    padding: 6px 0;
    line-height: 1.428571429;
    font-size: 14px;
    color: #888;
    white-space: nowrap;
}
.ui-dialog-statusbar label:hover {
    color: #333;
}
.ui-dialog-statusbar input,
.ui-dialog-statusbar .label {
    vertical-align: middle;
}
.ui-dialog-button {
    float: right;
    white-space: nowrap;
}
.ui-dialog-footer button+button {
    margin-bottom: 0;
    margin-left: 10px;
}
.ui-dialog-footer button {
    width:auto;
    overflow:visible;
    display: inline-block;
    padding: 6px 20px;
    _margin-left: 8px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    -webkit-user-select: none;
       -o-user-select: none;
          user-select: none;
}

.ui-dialog-footer button:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.ui-dialog-footer button:hover,
.ui-dialog-footer button:focus {
  color: #333333;
  text-decoration: none;
}

.ui-dialog-footer button:active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ui-dialog-footer button[disabled] {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}

.ui-dialog-footer button {
  color: #666;
  background-color: #F8F8F8;
  border-color: #ccc;
  outline: none;
}

.ui-dialog-footer button:hover,
.ui-dialog-footer button:focus,
.ui-dialog-footer button:active {
  color: #333333;
  background-color: #ebebeb;
  border-color: #adadad;
  outline: none;
}

.ui-dialog-footer button:active{
  background-image: none;
}

.ui-dialog-footer button[disabled],
.ui-dialog-footer button[disabled]:hover,
.ui-dialog-footer button[disabled]:focus,
.ui-dialog-footer button[disabled]:active {
  background-color: #ffffff;
  border-color: #cccccc;
  outline: none;
}

.ui-dialog-footer button.ui-dialog-autofocus {
  color: #ffffff;
  background-color: #ffc800;
  border-color: #ffc800;
    outline: none;
}

.ui-dialog-footer button.ui-dialog-autofocus:hover{
  color: #ffffff;
  background-color: #ffc800;
  border-color: #ffc800;
  outline: none;
}

.ui-dialog-footer button.ui-dialog-autofocus:active {
  background-image: none;
}
.ui-popup-top-left .ui-dialog,
.ui-popup-top .ui-dialog,
.ui-popup-top-right .ui-dialog {
    top: -8px;
}
.ui-popup-bottom-left .ui-dialog,
.ui-popup-bottom .ui-dialog,
.ui-popup-bottom-right .ui-dialog {
    top: 8px;
}
.ui-popup-left-top .ui-dialog,
.ui-popup-left .ui-dialog,
.ui-popup-left-bottom .ui-dialog {
    left: -8px;
}
.ui-popup-right-top .ui-dialog,
.ui-popup-right .ui-dialog,
.ui-popup-right-bottom .ui-dialog {
    left: 8px;
}

.ui-dialog-arrow-a,
.ui-dialog-arrow-b {
    position: absolute;
    display: none;
    width: 0;
    height: 0;
    overflow:hidden;
    _color:#FF3FFF;
    _filter:chroma(color=#FF3FFF);
    border:8px dashed transparent;
}
.ui-popup-follow .ui-dialog-arrow-a,
.ui-popup-follow .ui-dialog-arrow-b{
    display: block;
}
.ui-popup-top-left .ui-dialog-arrow-a,
.ui-popup-top .ui-dialog-arrow-a,
.ui-popup-top-right .ui-dialog-arrow-a {
    bottom: -16px;
    border-top:8px solid #e5e5e5;
}
.ui-popup-top-left .ui-dialog-arrow-b,
.ui-popup-top .ui-dialog-arrow-b,
.ui-popup-top-right .ui-dialog-arrow-b {
    bottom: -15px;
    border-top:8px solid #fff;
}
.ui-popup-top-left .ui-dialog-arrow-a,
.ui-popup-top-left .ui-dialog-arrow-b  {
    left: 15px;
}
.ui-popup-top .ui-dialog-arrow-a,
.ui-popup-top .ui-dialog-arrow-b  {
    left: 50%;
    margin-left: -8px;
}
.ui-popup-top-right .ui-dialog-arrow-a,
.ui-popup-top-right .ui-dialog-arrow-b {
    right: 15px;
}
.ui-popup-bottom-left .ui-dialog-arrow-a,
.ui-popup-bottom .ui-dialog-arrow-a,
.ui-popup-bottom-right .ui-dialog-arrow-a {
    top: -16px;
    border-bottom:8px solid #e5e5e5;
}
.ui-popup-bottom-left .ui-dialog-arrow-b,
.ui-popup-bottom .ui-dialog-arrow-b,
.ui-popup-bottom-right .ui-dialog-arrow-b {
    top: -15px;
    border-bottom:8px solid #fff;
}
.ui-popup-bottom-left .ui-dialog-arrow-a,
.ui-popup-bottom-left .ui-dialog-arrow-b {
    left: 15px;
}
.ui-popup-bottom .ui-dialog-arrow-a,
.ui-popup-bottom .ui-dialog-arrow-b {
    margin-left: -8px;
    left: 50%;
}
.ui-popup-bottom-right .ui-dialog-arrow-a,
.ui-popup-bottom-right .ui-dialog-arrow-b {
    right: 15px;
}
.ui-popup-left-top .ui-dialog-arrow-a,
.ui-popup-left .ui-dialog-arrow-a,
.ui-popup-left-bottom .ui-dialog-arrow-a {
    right: -16px;
    border-left:8px solid #e5e5e5;
}
.ui-popup-left-top .ui-dialog-arrow-b,
.ui-popup-left .ui-dialog-arrow-b,
.ui-popup-left-bottom .ui-dialog-arrow-b {
    right: -15px;
    border-left:8px solid #fff;
}
.ui-popup-left-top .ui-dialog-arrow-a,
.ui-popup-left-top .ui-dialog-arrow-b {
    top: 15px;
}
.ui-popup-left .ui-dialog-arrow-a,
.ui-popup-left .ui-dialog-arrow-b {
    margin-top: -8px;
    top: 50%;
}
.ui-popup-left-bottom .ui-dialog-arrow-a,
.ui-popup-left-bottom .ui-dialog-arrow-b {
    bottom: 15px;
}
.ui-popup-right-top .ui-dialog-arrow-a,
.ui-popup-right .ui-dialog-arrow-a,
.ui-popup-right-bottom .ui-dialog-arrow-a {
    left: -16px;
    border-right:8px solid #e5e5e5;
}
.ui-popup-right-top .ui-dialog-arrow-b,
.ui-popup-right .ui-dialog-arrow-b,
.ui-popup-right-bottom .ui-dialog-arrow-b {
    left: -15px;
    border-right:8px solid #fff;
}
.ui-popup-right-top .ui-dialog-arrow-a,
.ui-popup-right-top .ui-dialog-arrow-b {
    top: 15px;
}
.ui-popup-right .ui-dialog-arrow-a,
.ui-popup-right .ui-dialog-arrow-b {
    margin-top: -8px;
    top: 50%;
}
.ui-popup-right-bottom .ui-dialog-arrow-a,
.ui-popup-right-bottom .ui-dialog-arrow-b {
    bottom: 15px;
}
@keyframes ui-dialog-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ui-dialog-loading {
    vertical-align: middle;
    position: relative;
    display: block;
    *zoom: 1;
    *display: inline;
    overflow: hidden;
    width: 32px;
    height: 32px;
    top: 50%;
    margin: -16px auto 0 auto;
    font-size: 0;
    text-indent: -999em;
    color: #666;
}
.ui-dialog-loading {
    width: 100%\9;
    text-indent: 0\9;
    line-height: 32px\9;
    text-align: center\9;
    font-size: 12px\9;
}

.ui-dialog-loading:after {
    position: absolute;
    content: '';
    width: 3px;
    height: 3px;
    margin: 14.5px 0 0 14.5px;
    border-radius: 100%;
    box-shadow: 0 -10px 0 1px #ccc, 10px 0px #ccc, 0 10px #ccc, -10px 0 #ccc, -7px -7px 0 0.5px #ccc, 7px -7px 0 1.5px #ccc, 7px 7px #ccc, -7px 7px #ccc;
    -webkit-transform: rotate(360deg);
    -webkit-animation: ui-dialog-loading 1.5s infinite linear;
    transform: rotate(360deg);
    animation: ui-dialog-loading 1.5s infinite linear;
    display: none\9;
}


/*skin*/

.ui-dialog.dialog-alert{min-width: 255px; }
.ui-dialog.dialog-alert .ui-dialog-body{padding: 1em;}
.ui-dialog.dialog-alert .ui-dialog-grid{width:100%;}
.app-index-setter-modal {}
.app-index-setter-modal .ui-dialog-body{padding:15px;}
.pup-img .ui-dialog-body{padding: 0}
.pup-img .ui-dialog-footer{position: absolute;right: 0;top:0;width: 40px;height: 40px;padding: 0; text-align: center;background-color: rgba(0,0,0,0.5);}
.pup-img .ui-dialog-button{float: none;}
.pup-img .ui-dialog-footer .ui-dialog-button:before{content: 'x';color: #ffc800;display: inline-block;transform: scaleY(0.8);font-size: 30px; margin-top: -6px; }
.pup-img .ui-dialog-footer button{width: 100%;position: absolute; height: 100%; top: 0; right: 0; opacity: 0; }
.pup-img .u-button-cirle{position: absolute; right: 20px; bottom: 20px; width: 40px; min-width: auto; padding: 0; height: 40px; line-height: 40px;background: rgba(0,0,0,0.5); }