.message-tip {
    background-color: white;
    box-shadow: 1px 1px 3px #aaa;
    line-height: 1.5em;
    border-radius: 3px;
}
.message-tip .info-wrap>.col{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}
.message-tip .info-wrap>.col.c1{padding-right: 1em;}
.message-tip .info-wrap>.col.c2{}
.message-tip .info-wrap .c2 h6 {color: #999999;}
.message-tip .info-wrap .c2 p {font-size: 1em;margin-bottom: 0;}
.message-tip .info-wrap .icon {
    font-size: 2em;color: #0F81BF;
}
.message-tip .info-wrap .btn-close {
    display: block;
    position: absolute;
    font-size: 2em;
    z-index: 2;
    right: 5px;
    top:5px;
    color: red;
    width: 1em;
    height: 1em;
    text-align: center;
}
.message-tip .info-wrap{
    padding: 1em;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
}
