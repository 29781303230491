.module-login {
    display: flex;width: 100%;height: 100%;flex-flow: column;
    background-color: #CBF6CF;
    background-image: url("/assets/img/bg_01.png");
    background-repeat: no-repeat;
    background-position: top center;
}
.module-login .layout-header{ height:1rem;line-height:1rem;}
.module-login .layout-header .header{height:100%;display: flex;flex-flow: row;justify-content: space-between;line-height: inherit;}
.module-login .layout-header .header .header-item{line-height: inherit;display: flex;justify-content: flex-start;align-items: center;}
.module-login .layout-header .header .header-item-group{
    display: flex;line-height: inherit;
    flex-flow: row;
    justify-content: flex-start;
}
.module-login .layout-header .logo-text {font-size: .38rem;color: #111;}
.module-login .layout-header .logo-label1 {margin-right: .3em;}
.module-login .layout-header .logo-label2 {}
.module-login .layout-header .logo {display: inline-block;width:150px;margin-top:-2px;margin-left: .35rem;line-height: inherit;margin-right: .5em;}
.module-login .layout-header  span{display: inline-block;height: 100%;vertical-align: middle;line-height: inherit;}

.module-login .layout-content { display: flex;flex: 1 1;justify-content: center;align-items: center;}
.module-login .login-content-wrap {
    width: auto;height: auto;background-color: rgba(255,255,255,0.8);
    border-radius: .1rem;box-shadow: 0 0 .1rem;padding: .2rem 1rem;
}
.module-login .login-content{ }
.module-login .login-form-title{color: #21ccff;text-align: center;line-height: 3em;border-bottom: 2px solid #21ccff;font-size: .3rem; }
.module-login .login-form-row{ padding: 0.2rem 0; }
.module-login .btn-login{ margin-top: 0.2rem; }
