/* 字体颜色 */
/* 页面命名规范
    P- 代表页面
    M- 代表模块
    C- 代表组件
    U- 代表ui
    layout- 页面布局 (通常用于 页面内)
    wrap-   包裹命名
    row-    横向命名
    box-    盒子命名
    card-   卡片命名
    item-   小项命名
*/
.u-txt-title{color:#333;font-size:16px}
.u-txt-normal{color:#333;font-size:12px}
.u-txt-subTitle{color:#333;font-size: 14px}
.u-txt-quote{color:#999;font-size: 12px}
.u-txt-ellipsis{white-space: nowrap; overflow: hidden;text-overflow: ellipsis}
    

.u-color-normal{color:#333;}
.u-color-white{color: hsl(0, 0%, 100%)}
.u-color-green{color: #18d47b}
.u-color-gray{color:#999;}
.u-color-red{color:#ff3636f0;}
.u-color-dark{color: #333;}
.u-color-blue{color: #00adee;}
.u-color-yellow{color: #ffc107;}
.u-color-pink{color: #FF7C7C;}
.u-color-orange{color: #fe860c;}


.u-size-10{font-size: 10px}
.u-size-12{font-size: 12px}
.u-size-13{font-size: 13px}
.u-size-14{font-size: 14px}
.u-size-16{font-size: 16px}
.u-size-18{font-size: 18px}
.u-size-20{font-size: 20px}
.u-size-24{font-size: 24px}
.u-size-26{font-size: 26px}
.u-size-28{font-size: 28px}
/* 对齐 */
.u-align-bottom, 
.u-align-middle{display: inline-block;}
.u-align-middle{vertical-align: middle;}
.u-align-top{vertical-align: top;}
.u-align-bottom{vertical-align: bottom;}
.u-align-center{text-align: center;}
.u-align-left{text-align: left;}
.u-align-right{text-align: right;}


/*按钮*/
[class*="u-btn"]{display: inline-block;padding: .4em}
[class*="u-btnLine"]{border: solid 1px}
[class*="u-btn"].radius-01{border-radius: .1rem}
[class*="u-btn"].radius-02{border-radius: .2rem}
[class*="u-btn"].radius-03{border-radius: .3rem}
[class*="u-btn"].radius-04{border-radius: .4rem}
[class*="u-btn"].radius-05{border-radius: .5rem}
[class*="u-btn"].radius-06{border-radius: .6rem}
[class*="u-btn"].radius-07{border-radius: .7rem}
[class*="u-btn"].radius-08{border-radius: .8rem}
[class*="u-btn"].radius-09{border-radius: .9rem}
[class*="u-btn"].radius-10{border-radius: 1rem}
[class*="u-btn"].radius-20{border-radius: 2rem}
[class*="u-btn"].radius-30{border-radius: 3rem}
[class*="u-btn"].radius-40{border-radius: 4rem}
[class*="u-btn"].radius-50{border-radius: 5rem}
[class*="u-btn-"]{color:#fff}
[class*="u-button-"]{position: relative;text-align: center}
[class*="a-button"]:after {
  content: '';
  position: absolute;
  transition: all 0.3s;
}
[class*="u-button-"].animate-line:hover:after {
  width: 100%;
  opacity: 1;
}
[class*="u-button-"].animate-line:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background: rgba(0,0,0,.1);
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
}

.u-button-green{line-height: 2.2;border-radius:2rem;background:#18d47b;color:#fff;overflow: hidden;}
.u-button-line{padding: .4rem .8rem;line-height: 2.2;border-radius:2rem;overflow: hidden;border: solid 1px}

/*背景*/
.u-map-white{background: #fff}
.u-map-red{background: #ff3636f0}
.u-map-green{background: #18d47b}
.u-map-pink{background: #FF7C7C}
.u-map-blue{background: #00adee}
.u-map-dark{background: #333}
.u-map-lightdark{background: #f2f2f2}
.u-map-gray{background: #999}
.u-map-lightGray{background: #ddd}
.u-map-orange{background: #fe860c}
.u-map-yellow{background: #fe860c}
.u-map-violet{background: #bb99fa}



[class*="u-btn-"].disable{background-color: #e6e6e6; color: #c4c4c7; border-color: #e6e6e6;}

/*边距*/

/* 弹性盒子flex */
[class*="u-flex"]{display: flex;flex-wrap:wrap}
[class*="u-flex"].column{flex-direction: column}
[class*="u-flex"].row{flex-direction: row}
.u-flex-nowrap{flex-wrap: nowrap;flex-direction: row}
.u-flex-axisX-left{justify-content: flex-start;}
.u-flex-axisX-right{justify-content: flex-end;}
.u-flex-axisX-center{justify-content: center; }
.u-flex-axisX-between{justify-content: space-between; }
.u-flex-axisX-around{justify-content: space-around; }
.u-flex-axisY-top{align-items: flex-start; }
.u-flex-axisY-bottm{align-items: flex-end; }
.u-flex-axisY-center{align-items: center; }
.u-flex-axisY-baseLine{align-items: flex-baseline; }
.u-flex-axisY-between{align-content: space-between; }
.u-flex-axisY-full{align-items: stretch; }
.u-flex-middle{align-items: center; justify-content: center; }
[class*="u-flex"].align-middle{align-self: center}
[class*="u-flex"].column{flex-flow: column;}
.u-flex-full{width: 100%; height: 100%;}
[class*="u-colFlex"]{flex: auto}
.u-colFlex-1{flex: 1 1}
.u-colFlex-2{flex: 2 1}

[class*="u-col-"]{display: inline-block}
.u-col-1{width: 100%;}
.u-col-2{width: 50%;}
.u-col-3{width: 33.33%;}
.u-col-4{width: 25%;}
.u-col-5{width: 20%;}
.u-col-6{width: 16.66%;}
.u-col-7{width: 14.28%;}
.u-col-8{width: 12.5%;}
.u-col-9{width: 11.11%;}
.u-col-10{width: 10%;}
.u-col-20{width: 20%;}
.u-col-30{width: 30%;}
.u-col-40{width: 40%;}
.u-col-50{width: 50%;}
.u-col-60{width: 60%;}
.u-col-70{width: 70%;}
.u-col-80{width: 80%;}
.u-col-90{width: 90%;}
.u-col-100{width: 100%;}

/*style*/
.u-style-relative{position: relative;}
.u-style-absolute{position: absolute;}

.u-show-show,
.u-show-block{display: block;}
.u-show-hide{display: none !important;}
.u-show-clarity{opacity: 0 !important;}



/*title*/
[class*="u-title"]{color:#666; font-size: 14px}
.u-title-article{ color: #666; font-size: 12px; line-height: 2}
.u-title-normal{ text-align: left; font-size: 20px; position: relative; padding: 0 16px; line-height: 3; border-bottom: solid 1px #eaeaea; background: #fff;}
.u-title-normal:before{content: ''; display: inline; margin-left: -12px; margin-right: 12px; border-left: solid 4px #ffc107; left: 8px;}
.u-title-normal.noLine{border: none}
[class*="u-title-"]+[class*="u-wrap-"]{border-radius: 0 0 .6rem .6rem;}


/*wrap*/
.u-wrap-normal{padding: 1rem;border-radius: .6rem;background: #fff}


.u-chart-normal{height: 20rem;}

/*line*/
[class*="u-line-axis"]{position: absolute; background: #eaeaea;margin: auto}
.u-line-axisX{height: 1px;width: 100%;}
.u-line-axisY{width: 1px;height: 90%;top: 0; bottom: 0; right: 0; margin-right: -1px;}


/*辅助样式*/
.u-ast-middle:after{content:'';display: inline-block;height: 100%;vertical-align: middle;}
.u-ast-fill{ position: absolute; margin: auto; top:0; bottom: 0; left: 0; right: 0;}
.u-ast-table {display: table;  border-collapse: collapse;}
.u-ast-tr {display: table-row;}
.u-ast-th,.u-ast-td {display: table-cell;}

